@import "~antd/dist/reset.css";

/* content page login, center in page */
.login {
  width: 100%;
  height: 100%;
}

.img_logo{
  position: absolute;
  top: 0;
  left: 0;
}

.arrow_left{
  position: absolute;
  margin: 20px;
  top: 0;
  left: 0;
  font-size: 30px;
}

.content {
  width: 100%;
  justify-content: center;
  margin-top: 64px;
  padding: 24px;
}

.durationPicker .ant-picker-footer {
  display: none;
}

.durationPicker .ant-picker-time-panel-column:nth-child(3) {
  display: none;
}

.cell-content {
  padding: 10px;
}

.cell:hover .cell-content {
  border: 2px solid #F0F0F0;
  border-radius: 0.5em;
  padding: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cell-delete-button {
  display: none;
}

.cell:hover .cell-delete-button {
  display: block;
}

.work-time-card-plus {
  color: #F0F0F0;
  font-size: 3em;
  margin-top: 0.5em;
  padding: 0.1em;
  border: 1px solid #F0F0F0;
  border-radius: 0.3em;
}

.work-time-card:hover .work-time-card-plus {
  color: #d9d9d9;
  border: 1px solid #d9d9d9;
}

.dnd-filter-item:hover .dnd-filter-bar {
  background-color: gray;
  height: 0.25em;
  border-radius: 0.5em;
}

.dnd-filter-bar {
  background-color: lightgray;
  height: 0.25em;
  border-radius: 0.5em;
}

.work-time-calandar .ant-list-item {
  padding: 0.4em 0;
}

.work-time-calandar-week-end .ant-list-item {
  padding: 0 0;
}
.work-time-calandar-week-end .ant-list-item:first-child {
  margin-bottom: 0.5em;
}

.work-time-calandar .ant-list-grid .ant-col>.ant-list-item {
  margin-block-end: 0;
}

.day-not-completed-list .ant-list-pagination {
  margin-block-start: 0;
}
.work-time-card:hover .max-day-working-duration {
  display: block;
}

.work-time-card .max-day-working-duration {
  display: none;
}

.home-title .option-text {
  font-size: 38px;
  font-weight: 600;
}

